import MIAsvgNBA from '../assets/svgs/NBA/MIA.svg';
import BOSsvgNBA from '../assets/svgs/NBA/BOS.svg';
import LALsvgNBA from '../assets/svgs/NBA/LAL.svg';
import DENsvgNBA from '../assets/svgs/NBA/DEN.svg';
import GSWsvgNBA from '../assets/svgs/NBA/GSW.svg';
import MILsvgNBA from '../assets/svgs/NBA/MIL.svg';
import BKNsvgNBA from '../assets/svgs/NBA/BKN.svg';
import PHIsvgNBA from '../assets/svgs/NBA/PHI.svg';
import PHXsvgNBA from '../assets/svgs/NBA/PHX.svg';
import DALsvgNBA from '../assets/svgs/NBA/DAL.svg';
import MEMsvgNBA from '../assets/svgs/NBA/MEM.svg';
import UTAsvgNBA from '../assets/svgs/NBA/UTA.svg';
import ATLsvgNBA from '../assets/svgs/NBA/ATL.svg';
import CHIsvgNBA from '../assets/svgs/NBA/CHI.svg';
import TORsvgNBA from '../assets/svgs/NBA/TOR.svg';
import NYKsvgNBA from '../assets/svgs/NBA/NYK.svg';
import INDsvgNBA from '../assets/svgs/NBA/IND.svg';
import WASsvgNBA from '../assets/svgs/NBA/WAS.svg';
import CHAsvgNBA from '../assets/svgs/NBA/CHA.svg';
import ORLsvgNBA from '../assets/svgs/NBA/ORL.svg';
import DETsvgNBA from '../assets/svgs/NBA/DET.svg';
import CLEsvgNBA from '../assets/svgs/NBA/CLE.svg';
import MINsvgNBA from '../assets/svgs/NBA/MIN.svg';
import NOPsvgNBA from '../assets/svgs/NBA/NOP.svg';
import SACsvgNBA from '../assets/svgs/NBA/SAC.svg';
import SASsvgNBA from '../assets/svgs/NBA/SAS.svg';
import OKCsvgNBA from '../assets/svgs/NBA/OKC.svg';
import PORsvgNBA from '../assets/svgs/NBA/POR.svg';
import HOUsvgNBA from '../assets/svgs/NBA/HOU.svg';
import LACsvgNBA from '../assets/svgs/NBA/LAC.svg';

import SEAsvgNHL from '../assets/svgs/NHL/SEA.svg';
import DALsvgNHL from '../assets/svgs/NHL/DAL.svg';
import VGKsvgNHL from '../assets/svgs/NHL/VGK.svg';
import FLAsvgNHL from '../assets/svgs/NHL/FLA.svg';
import CARsvgNHL from '../assets/svgs/NHL/CAR.svg';
import BOSsvgNHL from '../assets/svgs/NHL/BOS.svg';
import TORsvgNHL from '../assets/svgs/NHL/TOR.svg';
import EDMsvgNHL from '../assets/svgs/NHL/EDM.svg';
import NYRsvgNHL from '../assets/svgs/NHL/NYR.svg';
import NJDsvgNHL from '../assets/svgs/NHL/NJD.svg';
import TBLsvgNHL from '../assets/svgs/NHL/TBL.svg';
import COLsvgNHL from '../assets/svgs/NHL/COL.svg';
import MINsvgNHL from '../assets/svgs/NHL/MIN.svg';
import WPGsvgNHL from '../assets/svgs/NHL/WPG.svg';
import CGYsvgNHL from '../assets/svgs/NHL/CGY.svg';
import PITsvgNHL from '../assets/svgs/NHL/PIT.svg';
import WSHsvgNHL from '../assets/svgs/NHL/WSH.svg';
import NSHsvgNHL from '../assets/svgs/NHL/NSH.svg';
import STLsvgNHL from '../assets/svgs/NHL/STL.svg';
import LAKsvgNHL from '../assets/svgs/NHL/LAK.svg';
import VANsvgNHL from '../assets/svgs/NHL/VAN.svg';
import SJSsvgNHL from '../assets/svgs/NHL/SJS.svg';
import ANAsvgNHL from '../assets/svgs/NHL/ANA.svg';
import BUFsvgNHL from '../assets/svgs/NHL/BUF.svg';
import DETsvgNHL from '../assets/svgs/NHL/DET.svg';
import OTTsvgNHL from '../assets/svgs/NHL/OTT.svg';
import MTLsvgNHL from '../assets/svgs/NHL/MTL.svg';
import PHIsvgNHL from '../assets/svgs/NHL/PHI.svg';
import CBJsvgNHL from '../assets/svgs/NHL/CBJ.svg';
import ARIsvgNHL from '../assets/svgs/NHL/ARI.svg';
import CHIsvgNHL from '../assets/svgs/NHL/CHI.svg';
import NYIsvgNHL from '../assets/svgs/NHL/NYI.svg';
import UTAsvgNHL from '../assets/svgs/NHL/UTA.svg';

import ARIsvgNFL from '../assets/svgs/NFL/ARI.svg';
import ATLsvgNFL from '../assets/svgs/NFL/ATL.svg';
import BALsvgNFL from '../assets/svgs/NFL/BAL.svg';
import BUFsvgNFL from '../assets/svgs/NFL/BUF.svg';
import CARsvgNFL from '../assets/svgs/NFL/CAR.svg';
import CHIsvgNFL from '../assets/svgs/NFL/CHI.svg';
import CINsvgNFL from '../assets/svgs/NFL/CIN.svg';
import CLEsvgNFL from '../assets/svgs/NFL/CLE.svg';
import DALsvgNFL from '../assets/svgs/NFL/DAL.svg';
import DENsvgNFL from '../assets/svgs/NFL/DEN.svg';
import DETsvgNFL from '../assets/svgs/NFL/DET.svg';
import GBsvgNFL from '../assets/svgs/NFL/GB.svg';
import HOUsvgNFL from '../assets/svgs/NFL/HOU.svg';
import INDsvgNFL from '../assets/svgs/NFL/IND.svg';
import JACsvgNFL from '../assets/svgs/NFL/JAC.svg';
import KCsvgNFL from '../assets/svgs/NFL/KC.svg';
import LARsvgNFL from '../assets/svgs/NFL/LAR.svg';
import LACsvgNFL from '../assets/svgs/NFL/LAC.svg';
import LVsvgNFL from '../assets/svgs/NFL/LV.svg';
import MIAsvgNFL from '../assets/svgs/NFL/MIA.svg';
import MINsvgNFL from '../assets/svgs/NFL/MIN.svg';
import NEsvgNFL from '../assets/svgs/NFL/NE.svg';
import NOsvgNFL from '../assets/svgs/NFL/NO.svg';
import NYGsvgNFL from '../assets/svgs/NFL/NYG.svg';
import NYJsvgNFL from '../assets/svgs/NFL/NYJ.svg';
import PHIsvgNFL from '../assets/svgs/NFL/PHI.svg';
import PITsvgNFL from '../assets/svgs/NFL/PIT.svg';
import SEAsvgNFL from '../assets/svgs/NFL/SEA.svg';
import SFsvgNFL from '../assets/svgs/NFL/SF.svg';
import TBsvgNFL from '../assets/svgs/NFL/TB.svg';
import TENsvgNFL from '../assets/svgs/NFL/TEN.svg';
import WASsvgNFL from '../assets/svgs/NFL/WAS.svg';

const teams = 
    {
         // NBA
         'Miami Heat': {
            shorthand: 'MIA',
            fullName: 'Miami Heat',
            logo: MIAsvgNBA,
        },
        'Boston Celtics': {
            shorthand: 'BOS',
            fullName: 'Boston Celtics',
            logo: BOSsvgNBA,
        },
        'Los Angeles Lakers': {
            shorthand: 'LAL',
            fullName: 'Los Angeles Lakers',
            logo: LALsvgNBA,
        },
        'Denver Nuggets': {
            shorthand: 'DEN',
            fullName: 'Denver Nuggets',
            logo: DENsvgNBA,
        },
        'Golden State Warriors': {
            shorthand: 'GSW',
            fullName: 'Golden State Warriors',
            logo: GSWsvgNBA,
        },
        'Milwaukee Bucks': {
            shorthand: 'MIL',
            fullName: 'Milwaukee Bucks',
            logo: MILsvgNBA,
        },
        'Brooklyn Nets': {
            shorthand: 'BKN',
            fullName: 'Brooklyn Nets',
            logo: BKNsvgNBA,
        },
        'Philadelphia 76ers': {
            shorthand: 'PHI',
            fullName: 'Philadelphia 76ers',
            logo: PHIsvgNBA,
        },
        'Phoenix Suns': {
            shorthand: 'PHX',
            fullName: 'Phoenix Suns',
            logo: PHXsvgNBA,
        },
        'Dallas Mavericks': {
            shorthand: 'DAL',
            fullName: 'Dallas Mavericks',
            logo: DALsvgNBA,
        },
        'Memphis Grizzlies': {
            shorthand: 'MEM',
            fullName: 'Memphis Grizzlies',
            logo: MEMsvgNBA,
        },
        'Utah Jazz': {
            shorthand: 'UTA',
            fullName: 'Utah Jazz',
            logo: UTAsvgNBA,
        },
        'Atlanta Hawks': {
            shorthand: 'ATL',
            fullName: 'Atlanta Hawks',
            logo: ATLsvgNBA,
        },
        'Chicago Bulls': {
            shorthand: 'CHI',
            fullName: 'Chicago Bulls',
            logo: CHIsvgNBA,
        },
        'Toronto Raptors': {
            shorthand: 'TOR',
            fullName: 'Toronto Raptors',
            logo: TORsvgNBA,
        },
        'New York Knicks': {
            shorthand: 'NYK',
            fullName: 'New York Knicks',
            logo: NYKsvgNBA,
        },
        'Indiana Pacers': {
            shorthand: 'IND',
            fullName: 'Indiana Pacers',
            logo: INDsvgNBA,
        },
        'Washington Wizards': {
            shorthand: 'WAS',
            fullName: 'Washington Wizards',
            logo: WASsvgNBA,
        },
        'Charlotte Hornets': {
            shorthand: 'CHA',
            fullName: 'Charlotte Hornets',
            logo: CHAsvgNBA,
        },
        'Orlando Magic': {
            shorthand: 'ORL',
            fullName: 'Orlando Magic',
            logo: ORLsvgNBA,
        },
        'Detroit Pistons': {
            shorthand: 'DET',
            fullName: 'Detroit Pistons',
            logo: DETsvgNBA,
        },
        'Cleveland Cavaliers': {
            shorthand: 'CLE',
            fullName: 'Cleveland Cavaliers',
            logo: CLEsvgNBA,
        },
        'Minnesota Timberwolves': {
            shorthand: 'MIN',
            fullName: 'Minnesota Timberwolves',
            logo: MINsvgNBA,
        },
        'New Orleans Pelicans': {
            shorthand: 'NOP',
            fullName: 'New Orleans Pelicans',
            logo: NOPsvgNBA,
        },
        'Sacramento Kings': {
            shorthand: 'SAC',
            fullName: 'Sacramento Kings',
            logo: SACsvgNBA,
        },
        'San Antonio Spurs': {
            shorthand: 'SAS',
            fullName: 'San Antonio Spurs',
            logo: SASsvgNBA,
        },
        'Oklahoma City Thunder': {
            shorthand: 'OKC',
            fullName: 'Oklahoma City Thunder',
            logo: OKCsvgNBA,
        },
        'Portland Trail Blazers': {
            shorthand: 'POR',
            fullName: 'Portland Trail Blazers',
            logo: PORsvgNBA,
        },
        'Houston Rockets': {
            shorthand: 'HOU',
            fullName: 'Houston Rockets',
            logo: HOUsvgNBA,
        },
        'Los Angeles Clippers': {
            shorthand: 'LAC',
            fullName: 'Los Angeles Clippers',
            logo: LACsvgNBA,
        },

        // NHL
        'Seattle Kraken': {
            shorthand: 'SEA',
            fullName: 'Seattle Kraken',
            logo: SEAsvgNHL,
        },
        'Dallas Stars': {
            shorthand: 'DAL',
            fullName: 'Dallas Stars',
            logo: DALsvgNHL,
        },
        'Vegas Golden Knights': {
            shorthand: 'VGK',
            fullName: 'Vegas Golden Knights',
            logo: VGKsvgNHL,
        },
        'Florida Panthers': {
            shorthand: 'FLA',
            fullName: 'Florida Panthers',
            logo: FLAsvgNHL,
        },
        'Carolina Hurricanes': {
            shorthand: 'CAR',
            fullName: 'Carolina Hurricanes',
            logo: CARsvgNHL,
        },
        'Boston Bruins': {
            shorthand: 'BOS',
            fullName: 'Boston Bruins',
            logo: BOSsvgNHL,
        },
        'Toronto Maple Leafs': {
            shorthand: 'TOR',
            fullName: 'Toronto Maple Leafs',
            logo: TORsvgNHL,
        },
        'Edmonton Oilers': {
            shorthand: 'EDM',
            fullName: 'Edmonton Oilers',
            logo: EDMsvgNHL,
        },
        'New York Rangers': {
            shorthand: 'NYR',
            fullName: 'New York Rangers',
            logo: NYRsvgNHL,
        },
        'New Jersey Devils': {
            shorthand: 'NJD',
            fullName: 'New Jersey Devils',
            logo: NJDsvgNHL,
        },
        'Tampa Bay Lightning': {
            shorthand: 'TBL',
            fullName: 'Tampa Bay Lightning',
            logo: TBLsvgNHL,
        },
        'Colorado Avalanche': {
            shorthand: 'COL',
            fullName: 'Colorado Avalanche',
            logo: COLsvgNHL,
        },
        'Minnesota Wild': {
            shorthand: 'MIN',
            fullName: 'Minnesota Wild',
            logo: MINsvgNHL,
        },
        'Winnipeg Jets': {
            shorthand: 'WPG',
            fullName: 'Winnipeg Jets',
            logo: WPGsvgNHL,
        },
        'Calgary Flames': {
            shorthand: 'CGY',
            fullName: 'Calgary Flames',
            logo: CGYsvgNHL,
        },
        'Pittsburgh Penguins': {
            shorthand: 'PIT',
            fullName: 'Pittsburgh Penguins',
            logo: PITsvgNHL,
        },
        'Washington Capitals': {
            shorthand: 'WSH',
            fullName: 'Washington Capitals',
            logo: WSHsvgNHL,
        },
        'Nashville Predators': {
            shorthand: 'NSH',
            fullName: 'Nashville Predators',
            logo: NSHsvgNHL,
        },
        'St Louis Blues': {
            shorthand: 'STL',
            fullName: 'St Louis Blues',
            logo: STLsvgNHL,
        },
        'Los Angeles Kings': {
            shorthand: 'LAK',
            fullName: 'Los Angeles Kings',
            logo: LAKsvgNHL,
        },
        'Vancouver Canucks': {
            shorthand: 'VAN',
            fullName: 'Vancouver Canucks',
            logo: VANsvgNHL,
        },
        'San Jose Sharks': {
            shorthand: 'SJS',
            fullName: 'San Jose Sharks',
            logo: SJSsvgNHL,
        },
        'Anaheim Ducks': {
            shorthand: 'ANA',
            fullName: 'Anaheim Ducks',
            logo: ANAsvgNHL,
        },
        'Buffalo Sabres': {
            shorthand: 'BUF',
            fullName: 'Buffalo Sabres',
            logo: BUFsvgNHL,
        },
        'Detroit Red Wings': {
            shorthand: 'DET',
            fullName: 'Detroit Red Wings',
            logo: DETsvgNHL,
        },
        'Ottawa Senators': {
            shorthand: 'OTT',
            fullName: 'Ottawa Senators',
            logo: OTTsvgNHL,
        },
        'Montréal Canadiens': {
            shorthand: 'MTL',
            fullName: 'Montréal Canadiens',
            logo: MTLsvgNHL,
        },
        'Philadelphia Flyers': {
            shorthand: 'PHI',
            fullName: 'Philadelphia Flyers',
            logo: PHIsvgNHL,
        },
        'Columbus Blue Jackets': {
            shorthand: 'CBJ',
            fullName: 'Columbus Blue Jackets',
            logo: CBJsvgNHL,
        },
        'Arizona Coyotes': {
            shorthand: 'ARI',
            fullName: 'Arizona Coyotes',
            logo: ARIsvgNHL,
        },
        'Chicago Blackhawks': {
            shorthand: 'CHI',
            fullName: 'Chicago Blackhawks',
            logo: CHIsvgNHL,
        },
        'New York Islanders': {
            shorthand: 'NYI',
            fullName: 'New York Islanders',
            logo: NYIsvgNHL,
        },
        'Utah Hockey Club': {
            shorthand: 'UTA',
            fullName: 'Utah Hockey Club',
            logo: UTAsvgNHL,
        },
        // NFL
        'Arizona Cardinals': {
            shorthand: 'ARI',
            fullName: 'Arizona Cardinals',
            logo: ARIsvgNFL
        },
        'Atlanta Falcons': {
            shorthand: 'ATL',
            fullName: 'Atlanta Falcons',
            logo: ATLsvgNFL
        },
        'Baltimore Ravens': {
            shorthand: 'BAL',
            fullName: 'Baltimore Ravens',
            logo: BALsvgNFL
        },
        'Buffalo Bills': {
            shorthand: 'BUF',
            fullName: 'Buffalo Bills',
            logo: BUFsvgNFL
        },
        'Carolina Panthers': {
            shorthand: 'CAR',
            fullName: 'Carolina Panthers',
            logo: CARsvgNFL
        },
        'Chicago Bears': {
            shorthand: 'CHI',
            fullName: 'Chicago Bears',
            logo: CHIsvgNFL
        },
        'Cincinnati Bengals': {
            shorthand: 'CIN',
            fullName: 'Cincinnati Bengals',
            logo: CINsvgNFL
        },
        'Cleveland Browns': {
            shorthand: 'CLE',
            fullName: 'Cleveland Browns',
            logo: CLEsvgNFL
        },
        'Dallas Cowboys': {
            shorthand: 'DAL',
            fullName: 'Dallas Cowboys',
            logo: DALsvgNFL
        },
        'Denver Broncos': {
            shorthand: 'DEN',
            fullName: 'Denver Broncos',
            logo: DENsvgNFL
        },
        'Detroit Lions': {
            shorthand: 'DET',
            fullName: 'Detroit Lions',
            logo: DETsvgNFL
        },
        'Green Bay Packers': {
            shorthand: 'GB',
            fullName: 'Green Bay Packers',
            logo: GBsvgNFL
        },
        'Houston Texans': {
            shorthand: 'HOU',
            fullName: 'Houston Texans',
            logo: HOUsvgNFL
        },
        'Indianapolis Colts': {
            shorthand: 'IND',
            fullName: 'Indianapolis Colts',
            logo: INDsvgNFL
        },
        'Jacksonville Jaguars': {
            shorthand: 'JAC',
            fullName: 'Jacksonville Jaguars',
            logo: JACsvgNFL
        },
        'Kansas City Chiefs': {
            shorthand: 'KC',
            fullName: 'Kansas City Chiefs',
            logo: KCsvgNFL
        },
        'Los Angeles Rams': {
            shorthand: 'LAR',
            fullName: 'Los Angeles Rams',
            logo: LARsvgNFL
        },
        'Los Angeles Chargers': {
            shorthand: 'LAC',
            fullName: 'Los Angeles Chargers',
            logo: LACsvgNFL
        },
        'Las Vegas Raiders': {
            shorthand: 'LV',
            fullName: 'Las Vegas Raiders',
            logo: LVsvgNFL
        },
        'Miami Dolphins': {
            shorthand: 'MIA',
            fullName: 'Miami Dolphins',
            logo: MIAsvgNFL
        },
        'Minnesota Vikings': {
            shorthand: 'MIN',
            fullName: 'Minnesota Vikings',
            logo: MINsvgNFL
        },
        'New England Patriots': {
            shorthand: 'NE',
            fullName: 'New England Patriots',
            logo: NEsvgNFL
        },
        'New Orleans Saints': {
            shorthand: 'NO',
            fullName: 'New Orleans Saints',
            logo: NOsvgNFL
        },
        'New York Giants': {
            shorthand: 'NYG',
            fullName: 'New York Giants',
            logo: NYGsvgNFL
        },
        'New York Jets': {
            shorthand: 'NYJ',
            fullName: 'New York Jets',
            logo: NYJsvgNFL
        },
        'Philadelphia Eagles': {
            shorthand: 'PHI',
            fullName: 'Philadelphia Eagles',
            logo: PHIsvgNFL
        },
        'Pittsburgh Steelers': {
            shorthand: 'PIT',
            fullName: 'Pittsburgh Steelers',
            logo: PITsvgNFL
        },
        'Seattle Seahawks': {
            shorthand: 'SEA',
            fullName: 'Seattle Seahawks',
            logo: SEAsvgNFL
        },
        'San Francisco 49ers': {
            shorthand: 'SF',
            fullName: 'San Francisco 49ers',
            logo: SFsvgNFL
        },
        'Tampa Bay Buccaneers': {
            shorthand: 'TB',
            fullName: 'Tampa Bay Buccaneers',
            logo: TBsvgNFL
        },
        'Tennessee Titans': {
            shorthand: 'TEN',
            fullName: 'Tennessee Titans',
            logo: TENsvgNFL
        },
        'Washington Commanders': {
            shorthand: 'WAS',
            fullName: 'Washington Commanders',
            logo: WASsvgNFL
        },
    }



export default teams;