import React from 'react';
import teams from '../data/teams';
import OutcomeCard from './OutcomeCard';
import { v4 as uuidv4 } from 'uuid';
import TeamInfo from './TeamInfo';

const EventCard = ({ bookmakers, commenceTime, awayTeam, homeTeam, selectedMarket, selectedBets, setSelectedBets, sport, customEventId }) => {
  const awayTeamData = teams[awayTeam];
  console.log(awayTeamData);
  const homeTeamData = teams[homeTeam];
  console.log(homeTeamData);

  // const bookmakersList = ['fanduel', 'draftkings', 'betmgm']
  const bookmakersArray = bookmakers;

  const commenceTimeDate = new Date(commenceTime);

  // Get day of the week
  const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'long' }).format(commenceTimeDate);

  // Get time in the format h:mm A
  const timeString = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(commenceTimeDate);

  // Get the time zone abbreviation
  const timeZone = commenceTimeDate.toLocaleTimeString('en-US', { timeZoneName: 'short' }).split(' ')[2];

  // Format the string
  const commenceTimeString = `${dayOfWeek} ${timeString} ${timeZone}`;


  return (
    <div className="event-card">
      <div className="event-team-row">
        <TeamInfo teamName={awayTeamData?.fullName} />
        <div className="event-team-odds-container">
          {bookmakersArray.map((bookmaker) => (
            <OutcomeCard key={uuidv4()} sport={sport} customEventId={customEventId} bookmaker={bookmaker} team={awayTeamData.fullName} totalSide={'Over'} selectedMarket={selectedMarket} selectedBets={selectedBets} setSelectedBets={setSelectedBets} />
          ))}
        </div>
      </div>
      <div className={"event-card-middle-row"} >
        <div className={"event-at-symbol"} >
          <h3 className="event-middle-row-text">@</h3>
        </div>
        <div className={"event-date-container"} >
          <h3 className="event-middle-row-text">{commenceTimeString}</h3>
        </div>
      </div>
      <div className="event-team-row">
        <TeamInfo teamName={homeTeamData?.fullName} />
        <div className="event-team-odds-container">
          {bookmakersArray.map((bookmaker) => (
            <OutcomeCard key={uuidv4()} sport={sport} customEventId={customEventId} bookmaker={bookmaker} team={homeTeamData.fullName} totalSide={'Under'} selectedMarket={selectedMarket} selectedBets={selectedBets} setSelectedBets={setSelectedBets} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default EventCard
