import React, { useContext } from 'react';
import { EventsContext } from '../context/EventsContext';

const ParlayCardLeg = ({ legDetails }) => {
  const events = useContext(EventsContext);
  const legEventDetails = events.find(event => event.customEventId === legDetails.customEventId);

  // If legEventDetails are undefined, the bet is no longer active and should not be included
  if (!legEventDetails) {
    return null;
  }

  // Use the commence_time if available, otherwise use a dummy date
  const commenceTimeDate = legEventDetails.commence_time 
    ? new Date(legEventDetails.commence_time) 
    : new Date();

  // Get short form of day of the week
  const dayOfWeek = new Intl.DateTimeFormat('en-US', { weekday: 'short' }).format(commenceTimeDate);

  // Get time in the format h:mm A
  const timeString = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(commenceTimeDate);

  // Extract day and month
  const monthDayString = new Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'short' }).format(commenceTimeDate);

  const legEventTime = `${monthDayString} ${timeString}`;

  return (
    <div className='bet-card-parlay-leg-container'>
      <div className='bet-card-leg-header flex'>
        <div className='bet-card-leg-selection-container flex-column'>
            <p className='bet-card-leg-selection'>{legDetails.outcome.name}</p>
            <p className='bet-card-leg-market'>{legDetails.market}</p>
        </div>
        <div className='bet-card-leg-odds-container'>
            <p className='bet-card-leg-odds'>{legDetails.oddsPrice}</p>
        </div>
      </div>
      <div className='bet-card-event-details-container flex'>
        <p className='bet-card-event-details'>
          {`${legEventDetails.away_team} @ ${legEventDetails.home_team}`}
        </p>
        <p className='bet-card-event-time'>{legEventTime}</p>
      </div>
    </div>
  )
}

export default ParlayCardLeg;